import {PaginatedPropertiesResult} from '@/properties/model/paginatedPropertiesResult';
import Property from '@/properties/model/property';
import SortBy from '@/properties/model/sortBy';
import SortDirection from '@/common/table/types/sortDirection';
import PropertyService from '@/properties/propertyService';
import DetailedProperty from "@/properties/model/detailedProperty";
import {ImageTypes} from "@/properties/model/imageTypes";
import {ImagesResponse} from "@/properties/model/imagesResponse";
import {ImageKeysResponse} from "@/properties/model/imageKeysResponse";
import {DetailedEstimation} from "@/properties/model/detailedEstimation";
import {Sharing} from "@/properties/model/sharing";
import {PropertyKeyResponse} from "@/properties/model/propertyKeyResponse";
import {EnergyType} from "@/properties/model/energyType";
import {ConsumptionEntry} from "@/properties/model/consumptionEntry";
import SharingSortBy from "@/properties/model/sharingSortBy";
import {PaginatedSharingResult} from "@/properties/model/paginatedSharingResult";
import {PropertyNameResponse} from "@/properties/model/propertyNameResponse";
import {AffectedServiceProviderInformation} from "@/properties/model/affectedServiceProviderInformation";
import {ImprovementSuggestion} from "@/properties/model/improvementSuggestion";
import {ImageAnalysis} from "@/properties/model/imageAnalysis";

export default class PropertiesPort {

    private propertyService: PropertyService;


    constructor(propertiesService: PropertyService) {
        this.propertyService = propertiesService;
    }

    async getPropertyByKey(propertyKey: string): Promise<Property> {
        return this.propertyService.getPropertyByKey(propertyKey);
    }

    async getDetailedPropertyByKey(propertyKey: string): Promise<DetailedProperty> {
        return this.propertyService.getDetailedPropertyByKey(propertyKey);
    }

    async getDetailedPropertiesByUserKey(): Promise<DetailedProperty[] | null> {
        return this.propertyService.getDetailedPropertiesByUserKey();
    }

    async storeDetailedProperty(property: DetailedProperty): Promise<DetailedProperty> {
        return this.propertyService.storeDetailedProperty(property);
    }

    async storeImage(image: string, imageType: number, propertyKey: string, floorNumber: number): Promise<string> {
        return this.propertyService.storeImage(image, imageType, propertyKey, floorNumber);
    }

    async storeNewThumbnail(originalImageKey: string, thumbnail: string): Promise<string> {
        return this.propertyService.storeNewThumbnail(originalImageKey, thumbnail);
    }

    async getImage(imageKey: string): Promise<string> {
        return this.propertyService.getImage(imageKey);
    }

    async getThumbnail(originalImageKey: string): Promise<string> {
        return this.propertyService.getThumbnail(originalImageKey);
    }

    async getImagesByType(type: ImageTypes, propertyKey: string): Promise<ImagesResponse[] | null> {
        return this.propertyService.getImagesByType(type, propertyKey);
    }

    async getImageKeysByType(type: ImageTypes, propertyKey: string): Promise<ImageKeysResponse[] | null> {
        return this.propertyService.getImageKeysByType(type, propertyKey);
    }

    async deleteImage(imageKey: string): Promise<boolean> {
        return this.propertyService.deleteImage(imageKey);
    }

    async updateProperty(propertyKey: string, newPropertyData: DetailedProperty): Promise<DetailedProperty> {
        return this.propertyService.updateProperty(propertyKey, newPropertyData);
    }

    async searchProperties(searchTerm: string, sortBy: SortBy, sortDirection: SortDirection, page: number, pageSize: number): Promise<PaginatedPropertiesResult> {
        return this.propertyService.searchProperties(searchTerm, sortBy, sortDirection, page, pageSize);
    }

    async searchSharings(searchTerm: string, categorySearchTerms: string[], sortBy: SharingSortBy, sortDirection: SortDirection, page: number, pageSize: number): Promise<PaginatedSharingResult> {
        return this.propertyService.searchSharings(searchTerm, categorySearchTerms, sortBy, sortDirection, page, pageSize);
    }

    async deleteProperty(uuid: string): Promise<AffectedServiceProviderInformation[]> {
        return this.propertyService.deleteProperty(uuid);
    }

    async deleteSharing(propertyKey: string, serviceProviderKey: string): Promise<PropertyNameResponse> {
        return this.propertyService.deleteSharing(propertyKey, serviceProviderKey);
    }

    async sharePropertyInformation(propertyKey: string, serviceProviderKey: string, categories: number[], propertyName: string, serviceProviderName: string): Promise<PropertyNameResponse | null> {
        return this.propertyService.sharePropertyInformation(propertyKey, serviceProviderKey, categories, propertyName, serviceProviderName);
    }

    async getSharingsByPropertyAndServiceProvider(serviceProviderKey: string, propertyKey: string): Promise<Sharing | null> {
        return this.propertyService.getSharingsByPropertyAndServiceProvider(serviceProviderKey, propertyKey);
    }

    async getSharingsByUserAndServiceProvider(userKey: string, serviceProviderKey: string): Promise<PropertyKeyResponse[] | null> {
        return this.propertyService.getSharingsByUserAndServiceProvider(userKey, serviceProviderKey);
    }

    async updateConsumptionData(propertyKey: string, date: Date, value: number, type: EnergyType): Promise<boolean> {
        return this.propertyService.updateConsumptionData(propertyKey, date, value, type);
    }

    async deleteConsumptionDataEntry(propertyKey: string, date: Date, type: EnergyType): Promise<boolean> {
        return this.propertyService.deleteConsumptionDataEntry(propertyKey, date, type);
    }

    async getConsumptionData(propertyKey: string, type: EnergyType): Promise<ConsumptionEntry[] | null> {
        return this.propertyService.getConsumptionData(propertyKey, type);
    }

    async getDetailedEstimation(propertyKey: string): Promise<DetailedEstimation | null> {
        return this.propertyService.getDetailedEstimation(propertyKey);
    }

    async requestDetailedEstimation(detailedProperty: DetailedProperty): Promise<DetailedEstimation | null> {
        return this.propertyService.requestDetailedEstimation(detailedProperty);
    }

    async getImprovementSuggestion(propertyKey: string): Promise<ImprovementSuggestion[] | null> {
        return this.propertyService.getImprovementSuggestion(propertyKey);
    }

    async requestSuggestionForImprovement(detailedEstimation: DetailedEstimation, propertyKey: string): Promise<ImprovementSuggestion[] | null> {
        return this.propertyService.requestSuggestionForImprovement(detailedEstimation, propertyKey);
    }

    async deleteImprovementSuggestions(): Promise<boolean> {
        return this.propertyService.deleteImprovementSuggestions();
    }

    async requestNewEstimation(detailedEstimation: DetailedEstimation): Promise<DetailedEstimation | null> {
        return this.propertyService.requestNewEstimation(detailedEstimation);
    }

    requestImageAnalysis(image: string, model: string, device: string, focalLength: number): Promise<ImageAnalysis[] | null> {
        return this.propertyService.requestImageAnalysis(image, model, device, focalLength);
    }
}
